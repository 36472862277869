import React from 'react'

import demo1 from '../../images/demo/1.jpg'
import demo2 from '../../images/demo/2.jpg'
import demo3 from '../../images/demo/3.jpg'
import demo4 from '../../images/demo/4.jpg'
import demo5 from '../../images/demo/5.jpg'
import demo6 from '../../images/demo/6.jpg'
import demo7 from '../../images/demo/7.jpg'
import demo8 from '../../images/demo/8.jpg'
import demo9 from '../../images/demo/9.jpg'
import demo10 from '../../images/demo/10.jpg'

const Demo = () => {
    return (
        <section className="wpo-demo-section section-padding" id="demo">
            <div className="container-fluid">
                <div className="row justify-content-center">
                    <div className="col col-lg-6">
                        <div className="wpo-section-title">
                            <h2>Here is Our Beautiful Homepages to Start Your Design!</h2>
                            <p>The Ultimate Niche HTML Template for the Weddings & Wedding Planner</p>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col col-xs-12">
                        <div className="wpo-demo-grids clearfix">
                            <div className="grid" >
                                <div className="inner">
                                    <a href="https://wpocean.com/html/tf/love-love/" target="_blank"><img src={demo1} alt="" /></a>
                                </div>
                                <h3>Main Home</h3>
                            </div>
                            <div className="grid">
                                <div className="inner">
                                    <a href="https://wpocean.com/html/tf/love-love/index-2.html" target="_blank"><img src={demo2} alt="" /></a>
                                </div>
                                <h3>Wedding Planner</h3>
                            </div>
                            <div className="grid">
                                <div className="inner">
                                    <a href="https://wpocean.com/html/tf/love-love/index-3.html" target="_blank"><img src={demo3} alt="" /></a>
                                </div>
                                <h3>Announcement Home</h3>
                            </div>
                            <div className="grid">
                                <div className="inner">
                                    <a href="https://wpocean.com/html/tf/love-love/index-4.html" target="_blank"><img src={demo4} alt="" /></a>
                                </div>
                                <h3>Wedding Home</h3>
                            </div>
                            <div className="grid">
                                <div className="inner">
                                    <a href="https://wpocean.com/html/tf/love-love/index-5.html" target="_blank"><img src={demo5} alt="" /></a>
                                </div>
                                <h3>Asian / Indian Wedding Announcement</h3>
                            </div>
                            <div className="grid">
                                <div className="inner">
                                    <a href="https://wpocean.com/html/tf/love-love/index-6.html" target="_blank"><img src={demo6} alt="" /></a>
                                </div>
                                <h3>Asian / Indian Wedding Planner</h3>
                            </div>
                            <div className="grid">
                                <div className="inner">
                                    <a href="https://wpocean.com/html/tf/love-love/index-7.html" target="_blank"><img src={demo7} alt="" /></a>
                                </div>
                                <h3>Muslim Wedding Announcement</h3>
                            </div>
                            <div className="grid">
                                <div className="inner">
                                    <a href="https://wpocean.com/html/tf/love-love/index-8.html" target="_blank"><img src={demo9} alt="" /></a>
                                </div>
                                <h3>Shop Home</h3>
                            </div>
                            <div className="grid">
                                <div className="inner-s2">
                                    <a href="https://wpocean.com/html/tf/love-love/index-9.html" target="_blank"><img src={demo10} alt="" /></a>
                                </div>
                                <h3>Wedding Invitation</h3>
                            </div>
                            <div className="grid">
                                <div className="inner-s">
                                    <img src={demo8} alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

    )
}

export default Demo;