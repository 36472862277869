import React, { Component } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import pg1 from '../../images/demo/11.jpg'
import pg2 from '../../images/demo/12.jpg'
import pg3 from '../../images/demo/13.jpg'
import pg4 from '../../images/demo/14.jpg'
import pg5 from '../../images/demo/15.jpg'
import pg6 from '../../images/demo/16.jpg'
import pg7 from '../../images/demo/17.jpg'
import pg8 from '../../images/demo/18.jpg'
import pg9 from '../../images/demo/19.jpg'


class Pages extends Component {
    render() {
        var settings = {
            dots: false,
            arrows: true,
            speed: 3000,
            slidesToShow: 3,
            slidesToScroll: 1,
            autoplay: true,
            autoplaySpeed: 2500,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1,
                        infinite: true,
                    }
                },
                {
                    breakpoint: 991,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1
                    }
                },
                {
                    breakpoint: 767,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        };

        return (
            <section id="pages" className="wpo-inner-demo-section section-padding">
                <div className="container-fluid">
                    <div className="row justify-content-center">
                        <div className="col col-lg-5">
                            <div className="wpo-section-title">
                                <h2>45+ Impressive Inner Pages</h2>
                                <p>we have crafted many different beautiful inner single pages for you.</p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col col-xs-12">
                            <div className="wpo-inner-demo-grids clearfix wpo-demo-active">
                                <Slider {...settings}>
                                    <div className="grid" >
                                        <div className="inner">
                                            <a href="https://wpocean.com/html/tf/love-love/about.html" target="_blank"><img src={pg1} alt="" /></a>
                                        </div>
                                        <h3>About Page</h3>
                                    </div>
                                    <div className="grid" >
                                        <div className="inner">
                                            <a href="https://wpocean.com/html/tf/love-love/story.html" target="_blank"><img src={pg2} alt="" /></a>
                                        </div>
                                        <h3>Story Page</h3>
                                    </div>
                                    <div className="grid" >
                                        <div className="inner">
                                            <a href="https://wpocean.com/html/tf/love-love/shop.html" target="_blank"><img src={pg3} alt="" /></a>
                                        </div>
                                        <h3>Shop Page</h3>
                                    </div>
                                    <div className="grid">
                                        <div className="inner">
                                            <a href="https://wpocean.com/html/tf/love-love/shop-single.html" target="_blank"><img src={pg4} alt="" /></a>
                                        </div>
                                        <h3>Shop Single</h3>
                                    </div>
                                    <div className="grid" >
                                        <div className="inner">
                                            <a href="https://wpocean.com/html/tf/love-love/blog.html" target="_blank"><img src={pg5} alt="" /></a>
                                        </div>
                                        <h3>Blog page</h3>
                                    </div>
                                    <div className="grid">
                                        <div className="inner">
                                            <a href="https://wpocean.com/html/tf/love-love/blog-single.html" target="_blank"><img src={pg6} alt="" /></a>
                                        </div>
                                        <h3>Blog Single</h3>
                                    </div>
                                    <div className="grid">
                                        <div className="inner">
                                            <a href="https://wpocean.com/html/tf/love-love/accomodation.html" target="_blank"><img src={pg7} alt="" /></a>
                                        </div>
                                        <h3>Accomodation</h3>
                                    </div>
                                    <div className="grid">
                                        <div className="inner">
                                            <a href="https://wpocean.com/html/tf/love-love/team-single.html" target="_blank"><img src={pg8} alt="" /></a>
                                        </div>
                                        <h3>Team Single</h3>
                                    </div>
                                    <div className="grid">
                                        <div className="inner">
                                            <a href="https://wpocean.com/html/tf/love-love/checkout.html" target="_blank"><img src={pg9} alt="" /></a>
                                        </div>
                                        <h3>Checkout</h3>
                                    </div>
                                </Slider>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default Pages;