import React from 'react';

import elogo from '../../images/envato-logo.png'
import logo from '../../images/logo2.svg'

const Footer = () => {
    return(
        <div>
            <footer className="wpo-site-footer">
                <div className="wpo-upper-footer">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-8">
                                <div className="wpo-footer-text">
                                    <div className="logo"><img src={logo} alt="" /></div>
                                    <p>Best Template For Your Perfect Wedding </p>
                                    <a className="theme-btn" target="_blank" href="https://1.envato.market/4e9NO9">Purchase Now </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
            <a className="wpo-purchase-theme-btn" href="https://1.envato.market/4e9NO9" target="_blank"> 
                <img className="envato-logo" src={elogo} alt=""/> 
                <h2><span>$</span>12</h2>
            </a>
        </div>
    )
}

export default Footer;