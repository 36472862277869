import React from "react";
import AnchorLink from 'react-anchor-link-smooth-scroll'
import mouse from '../../images/slider/mouse-scroll.svg'
import hImg from '../../images/slider/right.png'

const Hero = () => {
  return(
  <section className="static-hero">
      <div className="hero-container">
          <div className="hero-inner">
              <div className="container-fluid">
                  <div className="slide-content">
                      <div data-swiper-parallax="300" className="slide-title">
                          <h2>Best Template For Your Perfect Wedding </h2>
                          <p>For weddings & wedding planners - LoveLove is a best choice for your website!</p>
                      </div>
                      <div className="clearfix"></div>
                      <div data-swiper-parallax="500" className="slide-btns">
                        <a target="_blank" className="theme-btn" href="https://1.envato.market/4e9NO9">Buy LoveLove</a>
                      </div>
                      <div className="mouse-scroll">
                        <AnchorLink href="#demo"><img src={mouse} alt="" /></AnchorLink>
                    </div>
                  </div>
              </div>
          </div>
      </div>
      <div className="hero-right">
        <img src={hImg} alt="" />
      </div>
  </section>
  )
}

export default Hero;