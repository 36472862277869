import React from 'react'


const Choose = () =>{
    return(
        <section id="demo" className="wpo-features-section section-padding">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col col-lg-6">
                        <div className="wpo-section-title">
                            <h2>Why Choose Our Template</h2>
                            <p>Make perfect website with our template. It is fully responsive & retina ready.</p>
                        </div>
                    </div>
                </div>
                <div className="wpo-features-wrapper">
                    <div className="row">
                        <div className="col-lg-3 col-md-6 col-12">
                            <div className="wpo-features-item">
                                <div className="wpo-features-icon">
                                    <div className="icon">
                                        <i className="fi flaticon-creativity"></i>
                                    </div>
                                </div>
                                <div className="wpo-features-text">
                                    <h2>Hand-crafted Design</h2>
                                    <p>Love me brings a brand new design for Wedding, Wedding planner and wedding shop  with style.</p>
                                </div>
                                <div className="visible-icon">
                                    <i className="fi flaticon-creativity"></i>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-12">
                            <div className="wpo-features-item">
                                <div className="wpo-features-icon">
                                    <div className="icon">
                                        <i className="fi flaticon-gift"></i>
                                    </div>
                                </div>
                                <div className="wpo-features-text">
                                    <h2>Lifetime License</h2>
                                    <p>A little mistake on your website could have a big impact on your/client's business. we provide lifetime license.</p>
                                </div>
                                <div className="visible-icon">
                                    <i className="fi flaticon-gift"></i>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-12">
                            <div className="wpo-features-item">
                                <div className="wpo-features-icon">
                                    <div className="icon">
                                        <i className="fi flaticon-support"></i>
                                    </div>
                                </div>
                                <div className="wpo-features-text">
                                    <h2>Top-Notch Support</h2>
                                    <p>We guarantee the best possible fastest support so that you don’t ever get stopped in the development of your work. </p>
                                </div>
                                <div className="visible-icon">
                                    <i className="fi flaticon-support"></i>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-12">
                            <div className="wpo-features-item">
                                <div className="wpo-features-icon">
                                    <div className="icon">
                                        <i className="fi flaticon-laptop"></i>
                                    </div>
                                </div>
                                <div className="wpo-features-text">
                                    <h2>Free Updates</h2>
                                    <p>We mainly focuse on our buyers demands. So, as a great help we update our themes fully free with thair demands. </p>
                                </div>
                                <div className="visible-icon">
                                    <i className="fi flaticon-laptop"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

    )
}

export default Choose;